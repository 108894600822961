<template>
    <div style="background-color: #ffffff;text-align: left;padding: 10px 10px;border-radius: 5px;">
        <div class="pageCenter text-left m-b-20">
            <div class="df-jc-s-b m-b-20">
                <el-button-group>
                    <el-button v-for="v of orderStatus" :key="v.id" @click="setOrderStatusForEnterpriseSearchEnum(v.id)" :type="queryFurnitureLibrary.orderStatus==v.id?'primary':''">{{v.text}}</el-button>
                </el-button-group>
                <!-- <div>
                    <el-date-picker v-model="searchTime" type="daterange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                    <el-input placeholder="请输入搜索内容" style="width: 350px;" v-model="searchValue" class="input-with-select">
                        <el-select v-model="searchType" style="width: 110px;" slot="prepend" placeholder="请选择">
                            <el-option :label="v.text" :value="v.id" v-for="v of searchTypes"></el-option>
                        </el-select>
                        <el-button slot="append" @click="search" icon="el-icon-search"></el-button>
                    </el-input>
                </div> -->
            </div>
            <div class="" v-if="queryFurnitureLibraryObj.content">
                <order callBack="callBack" v-for="(v,i) of queryFurnitureLibraryObj.content" :key="i" :orderData="v" @continuePay="continuePay" @resetData="resetData" class="m-t-20" :isShowSet="true"></order>
            </div>
            <div v-else class="p-50 c-2 text-center">还没有订单</div>
        </div>
        <div class="pagination" style="background-color: #ffffff;padding: 20px 0;">
            <el-pagination v-if="queryFurnitureLibraryObj.content" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="queryFurnitureLibraryObj.size" :total="queryFurnitureLibraryObj.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
            </el-pagination>
        </div>
        <el-dialog width="680px" title="审核售后" :visible.sync="dialogVisible">
            <el-form label-width="120px">
                <el-form-item label="售后类型">
                    <el-input disabled v-model="classify.newsAlbumName" autocomplete="off" placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item label="售后原因">
                    <el-input disabled v-model="classify.newsAlbumPhoto" autocomplete="off" placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item label="备注说明">
                    <el-input disabled v-model="classify.newsAlbumPhoto" type="textarea" autocomplete="off" placeholder="请输入名称"></el-input>
                    <!-- <img src="../../assets/logo.png" alt="" v-for="(v,i) of 4" class="dialogImg"> -->
                    <el-image class="dialogImg" :src="url" :preview-src-list="srcList">
                    </el-image>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="">同意</el-button>
                    <el-button @click="dialogVisible = false">拒绝</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="请扫码支付" :visible.sync="payDialogVisible" width="30%" :append-to-body='true'>
            <div>
                <span class="btn m-r-10" :class="{'select':form.schemeOrderPayChennel==0}" @click="form.schemeOrderPayChennel=0,reConfirmOrderScheme()">
                    <img src="@/assets/images/stockCar/WeChat.png" class="btnImg">
                    微信
                    <img src="@/assets/images/stockCar/corner.png" class="corner">
                </span>
                <span class="btn" :class="{'select':form.schemeOrderPayChennel==1}" @click="form.schemeOrderPayChennel=1,reConfirmOrderScheme()">
                    <img src="@/assets/images/stockCar/Alipay.png" class="btnImg">
                    支付宝
                    <img src="@/assets/images/stockCar/corner.png" class="corner">
                </span>
                <div class="m-t-30">
                    <div ref="myLoginQrCodeUrl"></div>
                </div>
                <div class="c-1 f16 m-t-20">剩余支付时间 <span class="time" style="color: red;font-size: 22px"><countDown ref="countDown" :s='payExpSeconds'></countDown> </span>超时订单自动关闭</div>
                <!-- <div class="m-t-30"></div> -->
            </div>
            <!--           <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
          </span> -->
        </el-dialog>
        <!-- <div ref="myLoginQrCodeUrl"></div> -->
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { schemeOrder } from '@/service/index.js'
import { localSet, localGet, localDel } from "@/store/store"
import QRCode from 'qrcodejs2'
export default {
    name: "Enter",
    data() {
        return {
            pageWidth: oucy.pageWidth,
            // 按钮组 0全部 1待支付 2待发货 3待收货 4待评价 5已完成 6已取消 7回收站
            orderStatus: [
                { text: '全部', id: 0 },
                { text: '待支付', id: 1 },
                { text: '待发货', id: 2 },
                { text: '待收货', id: 3 },
                { text: '待评价', id: 4 },
                { text: '已完成', id: 5 },
                // { text: '待处理', id: 6 },
                { text: '已取消', id: 6 },
                { text: '回收站', id: 7 }
                // { text: '已关闭', id: 9 },
            ],
            // 搜索
            searchTypes: [
                { text: '订单编号', id: 'orderId' },
                { text: '支付单号', id: 'orderTradeNo' },
                { text: '收货地址', id: 'postAddr' },
                { text: '收货电话', id: 'postPhone' },
                { text: '收货人名', id: 'postUsername' },
                { text: '商品名称', id: 'spuName' },
                { text: '用户名称', id: 'userName' },
            ],
            searchTime: [],
            searchValue: null,
            searchType: 'orderId',

            pageSizes: [0],

            statu: 0,
            input3: null,
            select: null,
            queryFurnitureLibraryObj: {},
            classify: {
                newsAlbumName: "退货退款",
                newsAlbumPhoto: "商家发错商品",
            },
            dialogVisible: !true,
            url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            srcList: [
                'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
                'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
            ],
            // 请求对象
            queryFurnitureLibrary: {
                enterpriseId: null,
                orderStatus: 0,
                limit: 5,
                start: 0,
                globalAttrOption: null,
                globalClassId: null,
                goodSort: 0,
                priceMax: null,
                priceMin: null,
            },
            // 继续发起支付
            payDialogVisible: !true,
            qrCode: null,
            payExpSeconds: 0,
            form: {},
        }
    },
    computed: {

    },
    mounted() {
        // let enterprise = localGet('enterprise')
        // if (enterprise && enterprise.id) {
        // this.queryFurnitureLibrary.enterpriseId = enterprise.id
        this.queryOrderForUser()
        // }


        this.eventBus.$off(this.$oucy.msgKeys.SPU_PAY_SUCCESS).$on(this.$oucy.msgKeys.SPU_PAY_SUCCESS, (res) => {
            console.log('支付事件：');
            console.log(res);
            this.payDialogVisible = !true
            this.queryOrderForUser()
        })
    },
    methods: {
        continuePay(v) {
            this.form = {
                orderId: v.id,
                payPlatform: 'browser',
                schemeOrderPayChennel: v.orderPayChannel,
                uuid: this.$oucy.getUUID(),
                type:'reConfirmOrderScheme',
            }
            localSet('payObject',{
                orderId: v.id,
                payPlatform: 'browser',
                fee:v.orderPayfee,
                schemeOrderPayChennel: v.orderPayChannel,
                uuid: this.$oucy.getUUID(),
                type:'reConfirmOrderScheme',
            })
            this.$oucy.go('/payCenter/pay')
            return
            this.reConfirmOrderScheme()
        },
        // 继续发起支付
        reConfirmOrderScheme() {
            schemeOrder.reConfirmOrderScheme(this.form).then(res => {
                this.payDialogVisible = true
                this.payExpSeconds=res.payExpSeconds
                setTimeout(() => {
                    this.$refs.countDown.myinit()
                    if(this.qrCode ==null){
                        this.qrCode = new QRCode(this.$refs.myLoginQrCodeUrl, {
                            // text: text,
                            // text: me.$oucy.baseHost+'/routerView/wechartLogin/'+me.$oucy.scanKeyOfPreLogin+'?p='+me.$oucy.getUUID(),
                            width: 146,
                            height: 146,
                            colorDark: '#000000',
                            colorLight: '#ffffff',
                            correctLevel: QRCode.CorrectLevel.H
                        })
                    }
                    this.creatQrCode(res.qrCode)
                },50)
                // this.qrCode=null
            }).catch(err=>{
                console.log(err)
            })
        },
        creatQrCode(text) {
            this.qrCode && this.qrCode.clear();
            this.qrCode.makeCode(text)
        },
        setOrderStatusForEnterpriseSearchEnum(v) {
            this.queryFurnitureLibrary.orderStatus = v
            this.queryOrderForUser()
        },
        // 切换页数
        sizeChange: function(pageSize) {
            const me = this;
            me.queryFurnitureLibrary.limit = pageSize;
            me.queryOrderForUser();
        },
        // 切换页码
        currentChange: function(current) {
            const me = this;
            me.queryFurnitureLibrary.start = current - 1;
            me.queryOrderForUser();
        },
        // 请求
        queryOrderForUser() {
            schemeOrder.queryOrderForUser(this.queryFurnitureLibrary).then(res => {
                this.queryFurnitureLibraryObj = res || {}
            })
        },
        callBack(v) {
            if (v.type == 'go') {
                this.$oucy.go(v.path)
            }
        },
        search() {

            // beginTime   订单创建时间区间[区间开始时间]           

            // endTime 订单创建时间区间[区间截止时间]           

            // orderId 内部订单号          
            // orderTradeNo    第三方支付订单号           
            // postAddr    收货地址           
            // postPhone   收货人联系电话        
            // postUsername    收货人姓名          
            // spuName 商品名称           
            // userName    用户名 
            if (this.searchTime && this.searchTime.length) {
                this.queryFurnitureLibrary.beginTime = this.searchTime[0]
                this.queryFurnitureLibrary.endTime = this.searchTime[1]
            } else {
                // if (!this.searchValue) {
                //     this.$message('')
                //     return
                // }
                this.queryFurnitureLibrary.beginTime = null
                this.queryFurnitureLibrary.endTime = null
            }
            this.queryFurnitureLibrary.orderId = null
            this.queryFurnitureLibrary.orderTradeNo = null
            this.queryFurnitureLibrary.postAddr = null
            this.queryFurnitureLibrary.postPhone = null
            this.queryFurnitureLibrary.postUsername = null
            this.queryFurnitureLibrary.spuName = null
            this.queryFurnitureLibrary.userName = null
            console.log(this.queryFurnitureLibrary)
            this.queryFurnitureLibrary[this.searchType] = this.searchValue
            this.queryFurnitureLibrary.start = 0
            this.queryOrderForUser()
        },
        resetData(){
           this.queryOrderForUser() 
        },
    }
}
</script>
<style scoped lang="less">
.titleBar {
    height: 100px;
    /*background-color: #0366C3;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.titleBar_left {
    /*background-color: #42b983;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.titleBar_left_txt {
    /*background-color: #409EFF;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    font-size: 25px;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px #eeeeee solid;
    color: gray;
}

.titleBar_right {
    font-size: 25px;
    color: gray;
}

.titleBar_right:hover {
    cursor: pointer;
    color: #000000;
}

.breadcrumbBar {
    padding: 10px 0;
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.breadcrumbBar.enterHeadWidth {
    padding: 0 60px;
}

.el-menu-vertical-demo {
    text-align: left;
    min-height: 700px;
    border-radius: 4px;
}

.el-menu-item,
.el-submenu__title {
    font-size: 16px;
}

.el-menu-item span {
    font-size: 16px;
}

.dialogImg {
    width: 104px;
    height: 104px;
    margin-right: 10px;
    margin-top: 10px;
}

.btn {
    line-height: 1.2;
    display: inline-block;
    padding: 6px 36px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #EAEAEA;
    position: relative;
    cursor: pointer;
    vertical-align: middle;

    .corner {
        position: absolute;
        right: 0;
        bottom: 0;
        display: none;
    }

    &.select {
        border: 1px solid #2090FF;
        color: #2090ff;

        .corner {
            display: inline-block;
        }
    }

    .btnImg {
        vertical-align: inherit;
    }
}

.btn+.btn {
    margin-left: 3px;
}
</style>